export default {
  home: {
    minute: 'minuto',
    price_info: '{mianfei} minutos gratis, máximo por día {currencyName}{fengding}, depósito {currencyName}{yajin}, depósito de alquiler gratis',
    feature_1: 'Diseño especial para carga inalámbrica',
    feature_2: 'Préstamo y devolución universal nacional',
    feature_3: 'Cuatro modos de carga, aptos para todos los teléfonos móviles',
    submit: 'Recargar',
    borrow: 'Pedir prestado',
    inputcodetitle: 'Ingrese el número de dispositivo en el gabinete',
    msg_rentcount: 'Actualmente tiene {zujieNum} pedidos que no están cerrados',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    msg_fail_title: 'Error',
    msg_fail_content: 'Error en el alquiler, inténtelo de nuevo',
    shoptime: 'Horario comercial',
    supportrental: 'Apoyo al alquiler',
    supportreturn: 'Apoyo a la devolución',
    useragreen: 'Haga clic para aceptar el "Formulario de autorización de débito delegado", "Acuerdo de usuario", "Acuerdo de recarga"',
    privacyagreen: 'Haga clic para aceptar el Acuerdo de privacidad',
    locate: 'Localizar un',
    battGo: 'Batt&Go',
    station: 'Estación',
    scan: 'Escanear',
    the: 'el',
    QR: 'QR',
    code: 'código',
    charge: 'Cargar',
    onthego: 'sobre la marcha',
    return: 'Devolver',
    anywhere: 'En cualquier lugar',
    step1: 'Encuentre la estación Batt&Go más cercana',
    step2: 'Escanee el código QR en la estación usando la aplicación Batt&Go en su teléfono para desbloquear su batería externa Batt&Go.',
    step3: 'Tome la batería externa desbloqueada de la estación y cárguela libremente, ¡donde quiera que esté!',
    step4: 'Coloque la batería externa en cualquiera de nuestras estaciones más cercanas a su ubicación y cuando le resulte conveniente.',
    rent_powerbank: '¡Alquila tu powerbank!',
    rent_grab_des: 'Coge la powerbank y carga mientras estás en movimiento, ¡sin límites!',
    borrow_error: 'Lo sentimos, la estación no está disponible en este momento. Elija una diferente',
    borrow_recommended: 'Estaciones recomendadas',
    borrow_text: 'Ver todas las estaciones'
  },
  loginborrow: {
    gologin: 'Iniciar sesión',
    powerbanktitle: 'Desbloquea tu banco de energía Battandgo',
    msg_fail_title: 'Error',
    msg_fail_user: 'Error al obtener la información del usuario. ¿Quieres volver a intentarlo?',
    msg_rentcount: 'Actualmente tienes {zujieNum} pedidos que no están cerrados',
    msg_fail_support: 'El sistema no es compatible',
    msg_fail_confirm: 'Confirmar',
    msg_fail_retry: 'Reintentar',
    msg_fail_cancel: 'Cancelar',
    msg_fail_order: 'Error al crear el pedido de alquiler',
    msg_fail_paymod: 'Error al obtener el método de pago',
    msg_fail_login: 'Error al iniciar sesión',
    Apple: 'Apple',
    otheropt: 'Otras opciones',
    selectmethod: 'Selecciona tu método de pago',
    paymentdetail: 'Detalles del método de pago',
    couponcode: 'Código de cupón',
    rent: 'Confirmar pago',
    pricing: 'Ver precios',
    phone: 'Teléfono',
    email: 'Correo electrónico',
    Google: 'Google',
    Facebook: 'Facebook',
    loginfb: 'Iniciar sesión con Facebook',
    logingg: 'Iniciar sesión con Google',
    captcha_code: 'Código',
    phonenumber: 'Ingresa tu número de teléfono',
    emailinput: 'Ingresa tu dirección de correo electrónico',
    msg_fail_code: 'Ingresa el código de verificación',
    msg_fail_phone: 'Ingresa tu número de teléfono',
    logout: 'Salir de la cuenta',
    savenow: 'Guardar ahora',
    delete: 'Eliminar',
    addcard: 'Ingresa los datos de la tarjeta',
    msg_fail_content: 'Error en el alquiler, vuelve a intentarlo',
    title: 'Confirma la autorización',
    policy: 'Política.',
    term: 'Términos y condiciones',
    and: 'y',
    privacy: 'Política de privacidad.',
    tips: 'Al continuar, aceptas nuestros ',
    cancel: 'CANCELAR',
    agree: 'ACEPTO'
  },
  preauth: {
    preauthortitle: 'Acerca de la preautorización',
    tip1: 'Al iniciar un alquiler, se coloca una preautorización en su método de pago. Esto es solo para garantizar que haya fondos suficientes disponibles para pagar su alquiler.',
    tip2: 'Su banco puede enviarle una notificación, pero no es un cargo: solo se le cobrará el precio de su alquiler.',
    tip3: 'Dependiendo de su banco, puede llevar desde algunas horas hasta algunos días que el monto se ajuste en sus estados de cuenta, esto es normal.'
  },
  rentcost: {
    rentcosttitle: '¿Cuánto cuesta el alquiler?',
    tip1: 'Si no devuelves la batería externa en un plazo de 2 días, además de la tarifa de alquiler, se te cobrará una tarifa por no devolución de {currency}{currencyName} {yajin}.',
    tip2: 'La duración se redondea a la media hora más cercana (30 o 60 minutos).',
    tip3: 'El alquiler de 1 día son 24 horas desde el momento en que comenzó el alquiler.',
    tip4: 'Algunos lugares pueden ofrecer algunas horas gratis o descuentos. ¡Disfrútalos!',
    Days: 'Días',
    Hours: 'Horas',
    Minutes: 'Minutos'
  },
  logincode: {
    login: 'Iniciar sesión',
    retry: 'Reenviar'
  },
  agreenconfirm: {
    title: 'Confirme la autorización',
    policy: 'Política.',
    term: "Términos y condiciones de Battandgo",
    and: 'y',
    privacy: 'Política de privacidad.',
    tips: 'Acepto',
    cancel: 'CANCELAR',
    agree: 'ACEPTO'
  },
  users: {
    username: 'Nombre de usuario',
    mobile: 'Móvil',
    login: 'Iniciar sesión',
    orders: 'Pedidos de alquiler',
    wallet: 'Mi billetera',
    joinus: 'Cooperación para unirse',
    langsetting: 'Configuración de idioma'
  },
  login: {
    phonenumber: 'Número de teléfono',
    verificationcode: 'Código de verificación',
    Rentpowerbank: 'Alquila un banco de energía',
    Rentpowerbanktip: 'Ingresa tu número de teléfono celular para comenzar',
    phone_number: 'Ingresa un número de teléfono móvil de {0} dígitos',
    captcha_code: 'Código',
    send_code: 'Enviar código',
    login: 'Iniciar sesión',
    agree: 'Al hacer clic en Iniciar sesión, aceptas ',
    readme: `Acuerdo de usuario`,
    msg_fail_login: 'Inicio de sesión fallido',
    msg_success_login: 'Inicio de sesión exitoso',
    msg_success_login_code: 'Recibido correctamente, descarga la aplicación para iniciar sesión y usarla',
    msg_fail_user: 'Error al obtener la información del usuario. ¿Quieres volver a intentarlo?',
    no_sms: 'No recibí el mensaje de texto',
    retry: 'Reenviar',
    receive: 'Aplicar',
    invite_pwd: 'Código de invitación',
    vip_agreen_tip: 'Al continuar, estás indicando que aceptas nuestros',
    vip_agreen_service: 'Términos del servicio',
    vip_agreen_and: 'y',
    vip_agreen_policy: 'Política de privacidad'
  },
  confirm: {
    title: 'Confirmación de pago',
    pay_label: 'Monto',
    addcard: 'Agregar tarjeta de billetera',
    price_tips: 'Haga clic para confirmar el pago, indicando que ha leído y aceptado los ',
    price_btn: '《Acuerdo de valor agregado》',
    pay_type: 'Seleccione su método de pago preferido',
    submit: 'Confirmar alquiler',
    charge: 'Confirmar recarga',
    msg_fail_title: 'Advertencia',
    msg_fail_order: 'Error al crear la orden de alquiler',
    msg_fail_confirm: 'Confirmar',
    msg_fail_retry: 'Reintentar',
    msg_fail_cancel: 'Cancelar'
  },
  join: {
    title: 'Asociación',
    call: 'Llamada',
    tips: 'Por favor, proporcione su información, ¡nos comunicaremos con usted en breve!',
    type1: 'Instalación',
    type2: 'Publicidad',
    type3: 'Otro',
    shop_name: 'Nombre del comerciante',
    contact_person: 'Persona de contacto',
    phone: 'Número de contacto',
    submit: 'Envío',
    noempty: 'No puede estar vacío'
  },
  mine: {
    title: 'Perfil de usuario',
    wallet: 'Mi billetera',
    balance: 'Mi saldo',
    mywalletcard: 'Mi tarjeta',
    addcard: 'Agregar tarjeta a la billetera',
    deposit: 'Depositar',
    wallet_in: 'Cargar',
    wallet_out: 'Retirar',
    wallet_log: 'Registro de billetera',
    order: 'Pedido',
    login_tips: 'Inicia sesión',
    msg_fail_title: 'Error',
    msg_fail_user: 'Error al obtener la información del usuario. ¿Desea intentarlo nuevamente?',
    help: 'Ayuda',
    setting: 'Configuración',
    logout: 'Cerrar sesión',
    unpaid: 'Sin pagar',
    using: 'En uso'
  },
  msg: {
    msg_rentingorder: 'Hay pedidos en alquiler',
    msg_email_error: 'Formato de correo electrónico incorrecto',
    msg_fail_title: 'Advertencia',
    deletecard: '¿Está seguro de eliminar esta tarjeta?',
    loading: 'Cargando...',
    title: 'Consejos',
    success: 'Operación exitosa',
    error: 'Error desconocido, inténtelo nuevamente más tarde.',
    roleerror: 'Permisos insuficientes, consulte al administrador',
    neterror: 'Error en la conexión de red. Inténtalo de nuevo más tarde',
    confirm: 'Confirmar',
    retry: 'Reintentar',
    cancel: 'Cancelar',
    msg_logout: '¿Estás seguro de salir de la cuenta?',
    vieworder: 'Ver pedido',
    addorselectcard: 'Agrega o selecciona un paquete de tarjetas para continuar',
    success_payment: 'Pago completado',
    fail_payment: 'Error en el pago',
    keyword: 'palabra clave',
    unpaid_order: 'Hay pedidos sin pagar',
    go_pay: 'Ir a pagar',
    no_more: 'No hay más',
    noshop: 'No hay comerciantes',
    msg_fail_code: 'Ingresa el código de verificación',
    msg_fail_phone: 'Ingresa tu número de teléfono',
    exceedorder: 'Ya tienes un pedido en alquiler, devuélvelo antes de continuar',
    offline: 'El dispositivo {devicecode} está fuera de línea, comunícate con el personal presente',
    permissionfail: 'Permiso denegado.',
    unbound: 'El código QR mixto {devicecode} no está vinculado al dispositivo',
    nobattery: 'No hay batería disponible en el dispositivo {devicecode}'
  },
  result: {
    title: '¡Powerbank desbloqueado!',
    tips: '¡Tu Powerbank está listo, cógelo y disfruta de tu día sin estrés!',
    download: 'DESCARGAR',
    msg_result_loading: 'El resultado del pago se está confirmando...',
    msg_tips_loading: 'La batería portátil está apareciendo, por favor espera pacientemente...',
    haveorder: 'Se ha generado el pedido oficial'
  },
  result_msg: {
    loading: 'Cargando...',
    refresh: 'Actualizar'
  },
  order: {
    title: 'Orden de arrendamiento',
    in_use: 'En uso',
    ended: 'Finalizado',
    msg_error: 'Solicitud fallida',
    no_data: 'Sin datos'
  },
  order_item: {
    order_number: 'N.°',
    venue_rented: 'Lugar alquilado',
    time_rented: 'Tiempo alquilado',
    venue_returned: 'Lugar devuelto',
    time_returned: 'Tiempo devuelto',
    charge_standard: 'Estándar de cobro',
    rent_occurred: 'Se produjo un alquiler',
    returned: 'Devuelto',
    unreturned: 'No devuelto',
    type_0: 'No completado',
    type_1: 'Alquiler',
    type_2: 'Cancelado',
    type_3: 'Devuelto',
    type_4: 'Se produjo un error',
    type_5: 'Horas extra',
    min: 'Mín',
    unpaid: 'Sin pagar',
    payment: 'Pago'
  },
  setting: {
    title: 'Configuración',
    lang: 'Idioma'
  },
  help: {
    title: 'Preguntas frecuentes'
  },
  wallet_in: {
    title: 'Agregar valor',
    price_label: 'Seleccione otro monto de recarga',
    price_placeholder: 'Ingrese el monto de recarga',
    balance: 'Saldo',
    chargemoney: 'Seleccione el monto de recarga',
    submit: 'Enviar',
    loading: 'Cargando...',
    msg_title: 'Recordatorio'
  },
  wallet_out: {
    title: 'Retiro',
    price_label: 'Por favor, introduzca el importe del retiro',
    price_placeholder: 'Por favor, introduzca el importe del retiro',
    balance: 'Saldo actual',
    balance_all: 'Retirar todo',
    deposit: 'Depósito',
    deposit_tips: 'Hay una orden de alquiler. El depósito de {pcurrencyName}{pyajin} no es reembolsable',
    submit: 'Enviar',
    apply: 'Aplicar',
    confirm: 'Confirmar',
    loading: 'Cargando...',
    msg_fail_price: 'El importe del retiro es incorrecto',
    msg_fail_balance: 'El importe del retiro no puede ser mayor que el saldo actual',
    msg_title: 'Error',
    msg_success_content: 'Aplicación de reembolso exitosa',
    tips_title: 'Explicación',
    btn: 'Enviar',
    error_tips: 'Por favor, introduzca la información completa'
  },
  map: {
    在线: "Abierto",
    离线: "Cerrado",
    title: "Estación cercana",
    result_msg: "No se encontró ninguna estación cercana",
    msg_title: "error",
    no_support: "¡Lo siento! Su navegador no admite servicios de ubicación",
    my_position: "Mi Ubicación",
    distance: "Distancia:",
    shop_time: "Horas",
    free: "Disponible para alquiler",
    return: "Espacios vacíos disponibles",
    shop_title: "Detalles del local",
    address: "Dirección",
    go_here: "Ir aquí",
    nav_help: "Ayuda",
    nav_search: "Buscar"
  },
  shop: {
    shop_nav_1: 'Todos',
    shop_nav_2: 'Disponible',
    shop_nav_3: 'Retornable',
    title: "Estación cercana",
    search_station: "Buscar una estación",
    search_station_tips: "Por favor ingrese el nombre de la estación",
    shop_power_banks: "Bancos de energía",
    shop_empty_slots: "Espacios vacíos",
    shop_address: "Dirección",
    shop_business_hours: "Abierto",
    shop_directions_station: "VAMOS",
    shop_station_website: "Ir a la web del negocio",
    shop_scan_qrcode: "Código QR"
  }
}
