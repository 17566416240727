<template>
  <div class="container">
    <div class="battandgo-main">
      <img
        alt="logo"
        class="battandgo_logo"
        src="../assets/battandgo/img_logo.png"
      />
      <img
        alt="scan"
        class="battandgo_scan_guid"
        src="../assets/battandgo/img_scan_guide.png"
      />
      <h3 class="battandgo_title">{{ i18n.rent_powerbank }}</h3>
      <p class="battandgo_desc">{{ i18n.rent_grab_des }}</p>
      <div
        class="battandgo_btn btn"
        @click="goRouter(`/loginborrow?qrcode=${qrcode}`)"
      >
        <span class="text">CONTINUAR</span>
        <img src="../assets/battandgo/icons_right.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { QR_CODE, ZH_CODE } from "../apis/cabinet"
import userMixin from "../mixins/user"
import { CREATE_VIP_ORDER, BALANCE_TO_RENT } from "../apis/payment"
import { USER_INFO, OEDER_COUNT } from "../apis/user"
export default {
  mixins: [userMixin],
  components: {},
  watch: {},
  computed: {
    ...mapState([
      "qrcode",
      "zhcode",
      "cabinet",
      "browser",
      "user",
      "system",
      "lang",
      "headLogo"
    ]),
    i18n() {
      return this.$t("home")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    title() {
      return process.env.VUE_APP_NAME
    },
    code() {
      return this.qrcode || this.zhcode
    },
    scanUrl() {
      if (this.qrcode) {
        return `${QR_CODE}?id=${this.qrcode}`
      }
      if (this.zhcode) {
        return `${ZH_CODE}/${this.zhcode}`
      }
      return ""
    },
    shopDesc() {
      return `${this.i18n.price_info}`.format(this.cabinet)
    },
    amount() {
      if (this.cabinet) {
        return this.cabinet.yajin
        // return (this.cabinet.yajin - this.cabinet.myyue).toFixed(2)
      } else {
        return 0
      }
    },
    isView() {
      return this.isScan && this.cabinet && !this.isBtns
    }
  },
  data() {
    return {
      isScan: false,
      isBtns: false,
      inputCodeShow: false,
      mainHeight: 400,
      ua: navigator.userAgent.toLowerCase(),
      markerList: [],
      oldMarkerList: [],
      cabinetCode: "",
      latitude: "113.788876", // 113.788876
      longitude: "22.703407", // 22.703407
      pay: process.env.VUE_APP_PAY === "1",
      userAgreement: false,
      privacyAgreement: false
    }
  },
  methods: {
    goRouter(path) {
      this.$router.push(path)
    },
    onDownload() {
      if (this.system === "ios") {
        window.open(process.env.VUE_APP_APP_STORE)
      } else {
        window.open(process.env.VUE_APP_GOOGLE_PLAY)
      }
    },
    onBack() {
      this.isScan = false
    },
    codeShowClose() {
      this.inputCodeShow = false
      this.cabinetCode = ""
    },
    onScan(isclick) {
      // if (!this.scanUrl && !isclick) return
      let url = this.scanUrl
      if (isclick && this.cabinetCode) {
        url = `${QR_CODE}?id=${this.cabinetCode}`
        this.$store.commit("qrcode", this.cabinetCode)
      } else if (!this.scanUrl && !isclick) {
        return
      }
      this.inputCodeShow = false
      this.cabinetCode = ""
      this.$loading(true)
      this.$post(
        url,
        {},
        (resp) => {
          this.$loading(false)
          this.$store.commit("cabinet", {
            qrcode: this.code,
            ...resp
          })
        },
        (err) => {
          console.log("QR_CODE ~ err:", err)
          this.$loading(false)
          this.$router.replace(`/borrow_error?qrcode=${this.code}`)
        }
      )
    },
    onSubmit() {
      if (!this.userAgreement || !this.privacyAgreement) {
        this.$toast("Please review and agree to the relevant agreement below")
        return
      }
      this.$router.push({
        path: "/confirm",
        query: {
          price: this.amount,
          payType: "YJ",
          cabinetID: this.cabinet.cabinetID
        }
      })
    },
    onBorrow() {
      this.$loading(true)
      this.$post(
        `${BALANCE_TO_RENT}?qrcode=${this.cabinet.qrcode}`,
        {},
        (resp) => {
          this.$loading(false)
          console.log(resp)
          if (resp.orderid) {
            this.$router.push(`/result?tradeNo=${resp.orderid}`)
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_content,
              confirmText: this.i18n.confirm,
              showCancel: false
            })
          }
        },
        (error) => {
          this.$loading(false)
          this.$toast(error)
        }
      )
    },
    onVIP() {
      this.$loading(true)
      this.$post(
        `${CREATE_VIP_ORDER}/${this.cabinet.qrcode}`,
        {},
        (resp) => {
          this.$loading(false)
          // console.log(resp)
          if (resp.orderid) {
            this.$router.push(`/result?tradeNo=${resp.orderid}`)
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_content,
              confirmText: this.i18n.confirm,
              showCancel: false
            })
          }
        },
        (error) => {
          this.$loading(false)
          // console.log(error)
          this.$toast(error)
        }
      )
    },
    loadUser() {
      this.$get(
        USER_INFO,
        {},
        (resp) => {
          if (resp.user) {
            this.$store.commit("user", resp.user)
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_user,
              showOk: false,
              // confirmText: this.i18nMsg.retry,
              cancelText: this.i18nMsg.cancel
            }).then(() => {
              this.loadUser()
            })
          }
        },
        (error) => {
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.error,
            showOk: false,
            // confirmText: this.i18nMsg.retry,
            cancelText: this.i18nMsg.cancel
          }).then(() => {
            this.loadUser()
          })
          console.log(error)
        }
      )
    },
    onCode() {
      if (this.user) {
        this.isBtns = false
        this.loadUser()
        this.onScan()
      } else {
        this.$router.push("/login")
      }
    },
    closeTouch() {
      let node = document.getElementsByTagName("body")[0]
      node.style.overflow = "hidden"
      document
        .getElementsByTagName("body")[0]
        .addEventListener("touchmove", this.handler, { passive: false }) // 阻止默认事件
    },
    openTouch() {
      let node = document.getElementsByTagName("body")[0]
      node.style.overflow = "auto"
      document
        .getElementsByTagName("body")[0]
        .removeEventListener("touchmove", this.handler, { passive: false }) // 打开默认事件
    },
    handler(e) {
      e.preventDefault()
    },
    getOrderCount() {
      this.$get(
        OEDER_COUNT,
        {},
        (resp) => {
          if (resp.noMoney) {
            this.$confirm({
              title: this.i18nMsg.title,
              content: this.i18nMsg.unpaid_order,
              confirmText: this.i18nMsg.go_pay,
              showCancel: false
            }).then(() => {
              this.$router.push("/order")
            })
          }
        },
        (error) => {
          console.log(error)
        }
      )
    },
    // 頭部主頁圖標按鈕
    goHome() {
      this.$store.commit("qrcode", "")
      // console.log('ditu', gMapContext)
      this.isScan = false
    }
  },
  async created() {
    // console.log('url: ', window.location.href)
    // let qrCode = this.qrcode || this.$route.query.qrcode
    // console.log('qrCode', qrCode)
    // if (qrCode) {
    //   this.$store.commit('qrcode', qrCode)
    //   if (this.user) {
    //     this.onCode()
    //   }
    // }
    // if (this.user) {
    //   this.getOrderCount()
    // }

    if (!this.user || !this.token) {
      if (!this.phone) {
        this.$store.commit("phone", this.$getMoble(6))
        await this.onSendCode(this.phoneNum || this.phone)
      } else {
        await this.onSendCode(this.phoneNum || this.phone)
      }
    }
    if (this.code) this.onScan()
  },
  mounted() {}
}
</script>

<style lang="less" scoped>
.text-green {
  color: #62b22f;
}
.home-head {
  width: 100%;
  font-size: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  // margin: 20px 0 0;
  padding: 46px 60px;
  .home-logo {
    height: 56px;
    background: #62b22f;
    padding: 10px;
    border-radius: 20px;
  }
  &-title {
    p {
      text-align: end;
      margin: 10px 0;
    }
    &-small {
      font-family: "Montserrat-Medium";
      font-size: 24px;
      color: #62b22f;
    }
    &-big {
      font-family: "Montserrat-SemiBold";
      font-size: 36px;
      font-weight: 500;
      color: #000;
    }
  }
}
.container {
  width: 100vw;
  min-height: 100vh;
  background-color: #fff;
}
.battandgo {
  &-main {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding-top: 80px;
  }
  &_logo {
    width: 160px;
  }
  &_scan_guid {
    width: 72%;
    margin-top: 20px;
  }
  &_title {
    font-size: 60px;
    color: #000;
    font-weight: bold;
    margin: 60px 0 24px;
  }
  &_desc {
    width: 86%;
    margin: 0;
    font-size: 30px;
    color: #000;
    text-align: center;
  }
  &_btn {
    width: 400px;
    margin-top: 64px;
    border-radius: 2em;
    font-weight: bold;
    img {
      width: 44px;
    }
  }
}
</style>
